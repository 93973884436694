import React, {memo} from 'react';
import TopText from '@/components/TopText'
import './index.scss'

const NewMedia = memo(() => {
    return (
        <div className="new-media-page">
            <TopText
                bgUrl="http://image.koayee.cn/ynzFile/2023-11-09/2-bg_1699527335347.png"
                imgUrl="http://image.koayee.cn/ynzFile/2023-11-10/2-2_1699609596158.png"
                width={752}
            />
            <div className="creators-box page-class">
                <h1 className="header-title-box">
                    AI 新媒体<br/>
                    About<br/>
                    AI New Media
                </h1>
                <div className="right-box">
                    <p className="text-zh">
                        AI赋能打破了传统信息生产的获取方式，通过AI对市场的分析，给出对应的视频策略，让信息内容更为丰富，实现账户新矩阵，甚至真正达到全方位、宽领域、全角度的“全知视角”，触发账号流量引爆，实现多元化创收。
                    </p>
                    <div className="line"/>
                    <p className="text-en">
                        The empowerment of AI has disrupted the traditional way of information production, enabling the
                        use of AI to analyze the market and provide corresponding video strategies, making information
                        content more diverse. This achieves a new matrix for accounts and even truly achieves an
                        all-encompassing, wide-ranging, and comprehensive "omniscient perspective," triggering explosive
                        account traffic and achieving diversified revenue generation.
                    </p>
                </div>
            </div>

            <div className="ai-code page-class">
                <h1 className="header-title-box">
                    AI 平台二维码<br/>
                    AI platform<br/>
                    QR code
                </h1>
                <div className="qr-list">
                    {list.map((item,index)=>{
                        return (
                            <div className="item" key={index}>
                                <img src={item.icon} alt="" className="qr"/>
                                <p className="title">{item.title}<br/>{index===list.length-1?'官方公众号':'官方抖音号'}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
});

export default NewMedia;

const list=[
    {
        title: '星座密语',
        icon: 'http://image.koayee.cn/ynzFile/2023-11-18/1_1700301569251.png',
    },
    {
        title: '瞬时鉴定师',
        icon: 'http://image.koayee.cn/ynzFile/2023-11-18/2_1700301573031.png',
    },
    {
        title: '人生百态小和尚',
        icon: 'http://image.koayee.cn/ynzFile/2023-11-18/3_1700301576832.png',
    },
    {
        title: 'AI梵心佛智慧',
        icon: 'http://image.koayee.cn/ynzFile/2023-11-18/4_1700301581265.png',
    },
    {
        title: '丸子会AI',
        icon: 'http://image.koayee.cn/ynzFile/2023-11-18/5_1700301584998.png',
    },
    {
        title: 'KOAYEE可以哥说AI',
        icon: 'http://image.koayee.cn/ynzFile/2023-11-18/6_1700301588735.png',
    },
    {
        title: '元子玩 Al',
        icon: 'http://image.koayee.cn/ynzFile/2023-11-18/7_1700301592057.png',
    },
    {
        title: 'koayee公众号',
        icon: 'http://image.koayee.cn/ynzFile/2023-11-18/8_1700301595167.png',
    }
]
