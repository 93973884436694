import React, { memo,useState,useEffect } from 'react';
import {useHistory} from 'react-router-dom'
import './index.scss'

const HomeTop = memo((props) => {
    // let history = useHistory;
    const [curIndex,setCurIndex]=useState(0)
    const onSwipe=(type)=>{
        setCurIndex(type==='pre'?curIndex-1:curIndex+1)
    }
    const onGo=(item)=>{
        props.history.push(item.url)
    }
  return (
      <div className="home-swipe page-class">
          <div className="swipe-box">
              <div className="swipe" style={{transform:`translateX(-${curIndex * 1540/16}rem)`}}>
                  {swipeList.map((item,index)=>{
                      return (
                          <div className={`swipe-item`} key={index}>
                              <div className="item-left">
                                  <h1 className="header-title" setdate={item.header_title_sub}>{item.header_title}</h1>
                                  <p className="title">{item.title}</p>
                                  <p className="text">{item.text}</p>
                                  <button className="button" onClick={()=>onGo(item)}>立即开启</button>
                              </div>
                              <img src={item.img} alt="" className="item-right"/>
                          </div>
                      )
                  })}
              </div>
          </div>

          <div className="pre-next">
              <p className="number">0{curIndex+1}</p>
              <p className="line"/>
              {curIndex===0
                  ? <img className="disabled" src="http://image.koayee.cn/ynzFile/2023-11-09/arrow_1699492620083.png" alt=""/>
                  : <img src="http://image.koayee.cn/ynzFile/2023-11-09/arrow_1699492620083.png" alt="" onClick={()=>onSwipe('pre')}/>
              }

              {curIndex===swipeList.length-1
                  ? <img className="disabled" src="http://image.koayee.cn/ynzFile/2023-11-09/arrow-right_1699492752378.png" alt=""/>
                  :<img src="http://image.koayee.cn/ynzFile/2023-11-09/arrow-right_1699492752378.png" alt="" onClick={()=>onSwipe('next')}/>
              }

          </div>
      </div>
  );
});

export default HomeTop;

const swipeList=[
    {
        header_title:"创作者联盟",
        header_title_sub:"Creator Alliance",
        title:"提供AI大神速成体系，打造AI天团，赋能百业千行，用AI创造财富。",
        text:"Provide an AI deity rapid completion system, create an AI sky group, empower a hundred industries and thousands of paths, and use AI to create wealth.",
        img:"http://image.koayee.cn/ynzFile/2023-11-14/1_1699932508846.png",
        url:"/core/creators"
    },
    {
        header_title:"AI 新媒体",
        header_title_sub:"AI New Media",
        title:"万物皆媒延伸信息采集维度，人机协同引领内容生产变革。",
        text:"Everything has become a medium to extend the dimensions of information collection, and human-machine collaboration is leading the transformation of content production.",
        img:"http://image.koayee.cn/ynzFile/2023-11-14/2_1699932513606.png",
        url:"/core/new-media"
    },
    {
        header_title:"AI 技能培训",
        header_title_sub:"AI Skills Training",
        title:"探索 AI 成功之路，满足千行百业必备基本技能的最新渴求，助力实现高质量发展。",
        text:"Explore the path of AI success to meet the latest needs for essential skills in various industries, and help achieve high-quality development.",
        img:"http://image.koayee.cn/ynzFile/2023-11-14/3_1699932516489.png",
        url:"/core/skill-training"
    },
    {
        header_title:"数字商城",
        header_title_sub:"Digital Mall",
        title:"生成式AI席卷电商领域，AIGC赋能用户增效降本，全方位渗透电商产业链。",
        text:"Generative AI is sweeping through the e-commerce field, empowering users to increase efficiency and reduce costs. It has comprehensively penetrated the entire e-commerce industry chain.",
        img:"http://image.koayee.cn/ynzFile/2023-11-14/4_1699932519687.png",
        url:"/core/digital-mall"
    }
]
