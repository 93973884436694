import React, { memo, useEffect } from 'react';
import './index.scss'
import wxShare from '@/utils/wxShare.js'

import { NavLink } from "react-router-dom";

const AboutUs = memo(() => {
    useEffect(() => {
        // setTimeout(() => {
        //     wxShare()
        // }, 1000)
    }, [])
    return (
        <div className="about-us page-class">
            <div className="us-top">
                <h1 className="header-title-box">
                    关于蓝波 <br />
                    About<br />
                    us
                </h1>
                <div className="right">
                    <p className="right-zh">
                        蓝波（广州）科技有限公司由区块链电商鼻祖GEC
                        环球电商创始团队成员与国内大厂高管共同创立。作为一家解决方案型技术公司，我们从成立之初就深化“小人物，大能量”的核心理念。与蚂蚁区块链携手合作，在“碳达峰碳中和”、“NFT（数字藏品）”和“元宇宙”等领域里取得了引领性的探索成果。目前，公司已经形成了四大业务板块：创作者联盟、AI新媒体、AI培训板块以及AIGC数字商城。其中，“创作者联盟”板块旨在推动“KOAYEE元宇宙”项目，为创作者们提供一个AI作品展示与交流的平台。同时，作为阿里巴巴认证全国官方服务机构，在“AI新媒体”与“AI培训板块”则利用先进的AI技术和大数据算法，为企业与个人提供AI技能培训和应用场景解决方案，并提供个人多类职业教育证书考试与AI订单测试；“AIGC数字商城”板块为用户提供多样化的数字商品和服务,并提供AI设计师线上接单服务，让创作学以致用，解决就业能力。四个板块形成一个良好的闭环状态。得益于公司的独特定位和业务策略，蓝波（广州）科技有限公司已经获得了富有哲科思维的投资新秀“窄门投资”的数百万元天使轮投资，展现了其在科技行业AI赛道的巨大潜力和前景。
                    </p>
                    <p className="right-line" />
                    <p className="right-en">
                        Bluewave (Guangzhou) Technology Co., Ltd. was co-founded by the founding members of GEC Global
                        E-commerce, the pioneer of blockchain e-commerce, and senior executives from major domestic
                        companies. As a solution-oriented technology company, we have deeply embraced the core concept
                        of "small individuals, big energy" since our inception. We have collaborated with Ant Blockchain
                        to achieve pioneering exploration results in areas such as "carbon peak and carbon neutrality,"
                        "NFT (digital collectibles)," and "metaverse." Currently, the company has formed four major
                        business segments: Creator Alliance, AI New Media, AI Training, and AIGC Digital Mall.The
                        Creator Alliance aims to promote the "KOAYEE Metaverse" project, providing a platform for AI
                        creators to showcase and exchange their works. As an official national service agency certified
                        by Alibaba, the AI New Media and AI Training segments utilize advanced AI technology and big
                        data algorithms to provide AI skills training and application solutions for enterprises and
                        individuals. They also provide certification exams for various professional AI skills and AI
                        order testing.The AIGC Digital Mall segment offers diversified digital products and services to
                        users and provides online order services for AI designers, enabling practical application of
                        creative skills and addressing employment capabilities. The four segments form a well-rounded
                        closed-loop system. Thanks to the company's unique positioning and business strategy, Bluewave
                        (Guangzhou) Technology Co., Ltd. has received angel investment in the millions of RMB from
                        Narrow Gate Investment, a promising investor with a philosophical mindset, demonstrating its
                        immense potential and prospects in the AI field of the technology industry.
                    </p>
                </div>
            </div>
            <div className="us-bottom">
                <h1 className="header-title-box">
                    公司关系图<br />
                    Company<br />
                    Relationship Chart
                </h1>
                <img src="http://image.koayee.cn/ynzFile/2023-11-07/2_1699355318238.png" alt="" className="img" />
            </div>
        </div>
    );
});

export default AboutUs;
