import React, {memo} from 'react';

const Rights = memo(() => {

    return (
        <div className="rights-box page-class">
            <div className="rights-item">
                <div className="item-header">
                    <img src="http://image.koayee.cn/ynzFile/2023-11-13/join-2_1699845073026.png" alt=""
                         className="tag"/>
                    <div className="title">
                        <img src="http://image.koayee.cn/ynzFile/2023-11-13/join-1_1699845123655.png" alt=""
                             className="icon"/>
                        <p className="text">标准版</p>
                    </div>
                    <div className="price">加盟费<p className="num">99000</p>/年</div>
                </div>
                <div className="item-center">
                    <h1 className="title">科技服务 创作者联盟</h1>
                    <p className="desc">
                        创作者孵化体系、线下导师资源、活动策划支持和作品区块链确<br/>权服务
                    </p>
                    <p className="price">37500元</p>

                    <h1 className="title">创作者配件</h1>
                    <p className="desc">Ai电脑等相关硬件以及全套Ai工具的落地支持和保修服务</p>
                    <p className="price">11799元</p>

                    <h1 className="title">AI学苑</h1>
                    <p className="desc">阿里巴巴认证课程 & KOAYEE官方课程以及全部课程的折扣代理权</p>
                    <p className="price">20999元</p>

                    <h1 className="title">文化传媒</h1>
                    <p className="desc">AI新媒体全套打法+数字人直播带货的教程和服务并同步更新最新赛道和AI工具</p>
                    <p className="price">25500元</p>

                    <h1 className="title">数字电商</h1>
                    <p className="desc">专属身份等级和会员、业务订单扶持与激励 以及税务法律支持</p>
                    <p className="price">5169元</p>

                    <h1 className="title">加盟商特权</h1>
                    <p className="desc">终身享受加盟商发展奖励以及加盟商会务权益支持</p>
                    <p className="price">15000元</p>
                </div>
                <div className="item-footer">
                    <p className="text">服务总价值</p>
                    <p className="price">115967元</p>
                </div>
            </div>
            <div className="rights-item">
                <div className="item-header">
                    <img src="http://image.koayee.cn/ynzFile/2023-11-13/join-2_1699845073026.png" alt=""
                         className="tag"/>
                    <div className="title">
                        <img src="http://image.koayee.cn/ynzFile/2023-11-13/join-1_1699845123655.png" alt=""
                             className="icon"/>
                        <p className="text">高级版</p>
                    </div>
                    <div className="price">加盟费<p className="num">169000</p>/年</div>
                </div>
                <div className="item-center">
                    <h1 className="title">科技服务 创作者联盟</h1>
                    <p className="desc desc-2">
                        享受标准加盟的<span className="s">双倍权益</span>
                    </p>
                    <p className="price">65000元</p>

                    <h1 className="title">创作者配件</h1>
                    <p className="desc">额外<span className="s">赠送1次</span>维修往返包邮服务</p>
                    <p className="price">12399元</p>

                    <h1 className="title">AI学苑</h1>
                    <p className="desc">额外享受<span className="s">最高的课程代理折扣</span>以及最新课程<span
                        className="s">优先内部体验学习</span></p>
                    <p className="price">22999元</p>

                    <h1 className="title">文化传媒</h1>
                    <p className="desc">享受标准加盟的<span className="s">双倍权益</span></p>
                    <p className="price">39000元</p>

                    <h1 className="title">数字电商</h1>
                    <p className="desc">额外享受<span className="s">更低的业务订单手续费比例</span>以及<span
                        className="s">￥60000元</span>的业务订单扶持</p>
                    <p className="price">65169元</p>

                    <h1 className="title">加盟商特权</h1>
                    <p className="desc">额外享受<span className="s">更高的加盟商发展奖励比例</span></p>
                    <p className="price">15000元</p>
                </div>
                <div className="item-footer">
                    <p className="text">服务总价值</p>
                    <p className="price">219567元</p>
                </div>
            </div>
        </div>
    );
});

export default Rights;

