import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';

import './index.scss'

const AppFooter = memo(() => {
  const list=[
    {
      title: '广州市天河区天河软件园建中路51-53号（PCI未来社区）',
      icon: 'http://image.koayee.cn/ynzFile/2023-11-06/footer-1_1699260235039.png',
    },
    {
      title: '官方唯一加盟热线：13580530688',
      icon: 'http://image.koayee.cn/ynzFile/2023-11-06/footer-2_1699260304928.png',
    },
    {
      title: '官方加盟咨询邮箱：happyli@lambootech.com',
      icon: 'http://image.koayee.cn/ynzFile/2023-11-06/footer-3_1699260343941.png',
    },
    {
      title: '官方招聘信息邮箱：gululi@lambootech.com',
      icon: 'http://image.koayee.cn/ynzFile/2023-11-06/footer-3_1699260343941.png',
    }
  ]
  const qrList=[
    {
      title: '官方公众号',
      icon: 'http://image.koayee.cn/ynzFile/2023-11-17/gzh_1700205375043.png',
    },
    {
      title: '官方抖音号',
      icon: 'http://image.koayee.cn/ynzFile/2023-11-17/dyh_1700205380421.png',
    },
    {
      title: '官方在线客服',
      icon: 'https://image.koayee.cn/koayeeFile/2023-12-05/image%2029%402x_1701759805334.png',
    }
  ]
  const toTop=()=>{
    window.scrollTo({
      top:0,
      left:0,
      behavior: "smooth"
    });
  }
  return (
      <div className="page-class app-footer">
        <NavLink to="/" exact>
          <img src={require('@/assets/img/logo.png')} alt="" className="logo"/>
        </NavLink>
        <div className="content">
          <div className="content-left">
            {list.map((item,index)=>{
              return (
                  <div className="item" key={index}>
                    <img src={item.icon} alt="" className="icon"/>
                    <p className="title">{item.title}</p>
                  </div>
              )
            })}
          </div>
          <div className="content-center">
            {qrList.map((item,index)=>{
              return (
                  <div className="item" key={index}>
                    <img src={item.icon} alt="" className="qr"/>
                    <p className="title">蓝波科技 <br/>{item.title}</p>
                  </div>
              )
            })}
          </div>
          <img src="http://image.koayee.cn/ynzFile/2023-11-06/top_1699261979901.png" alt="" className="content-right" onClick={()=>toTop()}/>
        </div>
        <a className="beian" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2021058690号-1</a>
      </div>
  );
});

export default AppFooter;
