import React, {memo, useState, useEffect, useRef} from 'react';
import {creatorList} from '@/services/api.js'

let current = 0
let pageNum=1
let list=[]
const WorksExhibition = memo(() => {
    const col0Ref = useRef(null)
    const col1Ref = useRef(null)
    const col2Ref = useRef(null)

    const [list0, setList0] = useState([])
    const [list1, setList1] = useState([])
    const [list2, setList2] = useState([])

    useEffect(() => {
        getList()
        return ()=>{
            current=0
            pageNum=1
            list=[]
        }
    }, [])

    const getList=()=>{
        let params={
            pageNum:pageNum,
            pageSize:20
        }
        creatorList(params).then(res=>{
            if(res.code===200){
                if(pageNum===1){
                    list=res.rows
                    setList0([res.rows[0]])
                }else {
                    list=list.concat(res.rows)
                    handleImageLoad()
                }
            }
        })
    }

    const handleImageLoad = (e) => {
        if (current < list.length-1) {
            current++
            // console.error('e=>', e,current)
            getWaterFall()

        }
    }
    const getWaterFall = () => {
        let h0 = col0Ref.current.clientHeight
        let h1 = col1Ref.current.clientHeight
        let h2 = col2Ref.current.clientHeight
        let min_h = [h0, h1, h2].sort((a, b) => a - b)[0]
        if (min_h === h0) {
            setList0([...list0, list[current]])
        } else if (min_h === h1) {
            setList1([...list1, list[current]])
        } else {
            setList2([...list2, list[current]])
        }
        // console.log('min_h=>',[h0,h1,h2],min_h,current)
    }

    const onMore=()=>{
        // let arr=[
        //     'https://image.koayee.cn/koayee-pic/20231107/169935009700981.png',
        //     'https://image.koayee.cn/koayee-large/20231107/1699342393755851.png',
        //     'https://image.koayee.cn/koayee-large/20231107/1699337507017442.png'
        // ]
        // list=list.concat(arr)
        pageNum++
        getList()
        // handleImageLoad()

    }
    return (
        <div className="works-exhibition page-class">
            <h1 className="header-title-box">
                作品展示<br/>
                Exhibition<br/>
                of works
            </h1>
            <div className="img-box">
                <div className="col" ref={col0Ref}>
                    {
                        list0.map((item, index) => {
                            return (
                                <img src={item.pic} alt="" className="item" key={index} onLoad={e => handleImageLoad(e)}/>
                            )
                        })
                    }
                </div>
                <div className="col" ref={col1Ref}>
                    {
                        list1.map((item, index) => {
                            return (
                                <img src={item.pic} alt="" className="item" key={index} onLoad={e => handleImageLoad(e)}/>
                            )
                        })
                    }
                </div>
                <div className="col" ref={col2Ref}>
                    {
                        list2.map((item, index) => {
                            return (
                                <img src={item.pic} alt="" className="item" key={index} onLoad={e => handleImageLoad(e)}/>
                            )
                        })
                    }
                </div>
            </div>
            <div className="more-button" onClick={()=>onMore()}>加载更多</div>
        </div>
    );
});

export default WorksExhibition;
// let list = [
    // 'https://image.koayee.cn/koayee-large/20231109/1699518735920788.png',
    // 'https://image.koayee.cn/koayee-large/20230915/1694763460924669.png',
    // 'https://image.koayee.cn/koayee-pic/20231108/16994110739659.png',
    // 'https://image.koayee.cn/koayee-large/20231109/1699518348038096.png',
    // 'https://image.koayee.cn/koayee-pic/20231109/169951683221970.png',
    // 'https://image.koayee.cn/koayee-pic/20231109/16995140378595.png',
    //
    // 'https://image.koayee.cn/koayee-pic/20231109/169951211275997.png',
    // 'https://image.koayee.cn/koayee-pic/20231109/169951388212943.png',
    // 'https://image.koayee.cn/koayee-large/20231109/1699513080993026.png',
    // 'https://image.koayee.cn/koayee-pic/20231109/16995140378595.png',
    // 'https://image.koayee.cn/koayee-pic/20231109/16995115406775.png',
    // 'https://image.koayee.cn/koayee-pic/20231109/169951146542646.png',
// ]
