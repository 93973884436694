import React, { memo } from 'react';
import TopText from '@/components/TopText'
import WorksExhibition from './WorksExhibition'
import './index.scss'

const Creators = memo(() => {
  return (
      <div className="creators-page">
        <TopText
            bgUrl="http://image.koayee.cn/ynzFile/2023-11-09/1-bg_1699516220369.png"
            imgUrl="http://image.koayee.cn/ynzFile/2023-11-10/2-1_1699609559936.png"
            width={712}
        />
        <div className="creators-box page-class">
          <h1 className="header-title-box">
            创作者联盟<br/>
            About<br/>
            Creator Alliance
          </h1>
          <div className="right-box">
            <p className="text-zh">
              随着人工智能的快速发展，生成式 AI 正以其惊人的创造力和无限潜力，改变着艺术和创意产业面貌。“创作者联盟”为降低用户的上手难度，让用户使用自然语言输入来实现自动化分析关键风格元素，并做出高品质图像，为用户带来更顺畅的内容创作体验，实现用户快速拥抱生成式 AI，驱动业务增长。
            </p>
            <div className="line"/>
            <p className="text-en">
              With the rapid development of artificial intelligence, generative AI is changing the face of the art and creative industries with its astonishing creativity and endless potential. In order to reduce the user's learning curve and provide a smoother content creation experience, the "Creator Alliance" allows users to use natural language input to automate the analysis of key style elements and produce high-quality images. This enables users to quickly embrace generative AI and drive business growth.
            </p>
          </div>
        </div>

        <WorksExhibition/>

      </div>
  );
});

export default Creators;
