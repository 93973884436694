const baseSize = 16
let timer=null
export function setRem() {
    let clientWidth = document.documentElement.clientWidth
    let scale = clientWidth / 1920
    if(clientWidth<600){//最小
        scale = 600 / 1920
    }else if(clientWidth>1920){ //最大
        scale = 1920 / 1920
    }
    // console.log(clientWidth)
    document.documentElement.style.fontSize = baseSize * scale + 'px'
    if(timer){
        clearTimeout(timer)
    }
    // timer=setTimeout(()=>{
    //     // console.error(clientWidth,scale)
    //     window.scale=scale.toFixed(3)
    // },500)
}
setRem()
window.onresize = function () {
    // console.log(66666)
    setRem()
}
