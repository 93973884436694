import React, {memo} from 'react';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import TopText from '@/components/TopText'
import './index.scss'

const SkillTraining = memo(() => {
    return (
        <div className="skill-training-page">
            <TopText
                bgUrl="http://image.koayee.cn/ynzFile/2023-11-10/3-bg_1699600328544.png"
                imgUrl="http://image.koayee.cn/ynzFile/2023-11-10/2-3_1699609643417.png"
                width={832}
            />
            <div className="creators-box">
                <h1 className="header-title-box">
                    AI 技能培训<br/>
                    About<br/>
                    AI Skills Training
                </h1>
                <div className="right-box">
                    <p className="text-zh">
                        独创三大课程板块，提供多元化互动式教程的学习体验，根据学员的水平和需求进行精准匹配，提供保姆式学习保障。此外，AI还可以智能评估学员的学习效果，帮助他们更好地掌握技能。
                    </p>
                    <div className="line"/>
                    <p className="text-en">
                        Introduce three innovative course modules, providing a diverse and interactive learning
                        experience through customized matching based on students' levels and needs, and ensuring a
                        nurturing learning guarantee. In addition, AI can intelligently assess students' learning
                        outcomes and assist them in mastering skills more effectively.
                    </p>
                </div>
            </div>

            <div className="course-box">
                <h1 className="header-title-box">
                    技能课程介绍<br/>
                    Skill Course<br/>
                    Introduction
                </h1>
                {list.map((item, index) => {
                    return (
                        <div className={['course-item',index%2?'odd':''].join(' ')} key={index}>
                            <img src={item.img} alt="" className="img"/>
                            <div className="text-box">
                                <p className="desc">{item.desc}</p>
                                <div className="tag-list">
                                    {
                                        item.tagList.map((j,jIndex)=>{
                                            return (
                                                <div className="tag-item" key={jIndex}>
                                                    <p className="num">{jIndex+1}</p>
                                                    <div className="text">
                                                        {j}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
});

export default SkillTraining;

const list = [
    {
        desc: '阿里巴巴认证课程由阿里巴巴集团设计并推出的一系列官方课程，课程涵盖互联网营销，天猫店长/客服体系，数据分析，直播，数字人，人工智能等多个领域，且所有课程都已拿到国家人社部的职业教育认证资质，可进行课程标准制定，课程制作与发布，考证安排，发证服务为一体的职业教育认证路径。',
        img: 'http://image.koayee.cn/ynzFile/2023-11-10/3-1_1699600777973.png',
        tagList:[
            <p>阿里巴巴人工智能训<br/>练师导学课</p>,
            <p>阿里巴巴人工智能训练师<br/>ChatGpt版</p>,
            <p>阿里巴巴人工智能训练师<br/>考证专业版</p>,
            <p>阿里巴巴互联网营销师<br/>三级证书</p>,
            <p>阿里巴巴互联网营销师<br/>四级证书</p>
        ]
    },
    {
        desc: 'KOAYEE官方认证课程由KOAYEE学苑官方设计并认证通过，基于快速入门，实战演练，能力塑造与市场交付为一体的打造标准，推向市场并联动官方创作者联盟，进行创作者实战与分类，致力于打造新一代AI设计天团。',
        img: 'http://image.koayee.cn/ynzFile/2023-11-10/3-2_1699600929222.png',
        tagList:['ChatGpt实战版课程','Midjourney快速入门','Stable Diffusion系统课程']
    },
    {
        desc: '宗师合作课程由蓝波生态各个板块鉴定与筛选，基于整个AI内容创作体系，AI新媒体服务体系以及实战演练，将市面上AI流行玩法，AI爆款视频进行拆解并输出内容制作与运营的一套小打法，以“点对点”形式实现个人单点爆发式增长。',
        img: 'http://image.koayee.cn/ynzFile/2023-11-10/3-3_1699601047976.png',
        tagList:['瞬时宇宙风格课','AI小和尚爆火课程','Heygen数字人教学','AI佛语动态教学']
    }
]
