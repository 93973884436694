import React, {memo, useEffect} from 'react';
import TopText from '@/components/TopText'
import Benefits from './Benefits'
import Position from './Position'
import './index.scss'

const Recruitment = memo(() => {

  return (
      <div className="recruitment-page">
        <TopText
            bgUrl="http://image.koayee.cn/ynzFile/2023-11-15/bg_1700013101638.png"
            imgUrl="http://image.koayee.cn/ynzFile/2023-11-15/Group138_1700013064258.png"
            width={697}
        />
          <Benefits/>
          <Position/>
      </div>
  );
});

export default Recruitment;
