import React, {memo, useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom'
import TopText from '@/components/TopText'
import {dataType,newsList} from '@/services/api.js'
import './index.scss'

const News = memo((props) => {
  let history = useHistory;
    const [current, setCurrent] = useState(0)
    const [tabsList, setTabsList] = useState([])
    const [list, setList] = useState([])

    useEffect(()=>{
        dataType('news_type').then(res=>{
            if(res.code===200){
                res.data.unshift({dictLabel:'全部新闻',dictValue:0})
                setTabsList(res.data)
            }
        })
        getList()
    },[])

    const getList=(type)=>{
        let params={
            pageNum:1,
            pageSize:100,
            ftype:type||''
        }
        newsList(params).then(res=>{
            if(res.code===200){
                setList(res.rows)
            }
        })
    }
    const onTab = (item) => {
        setCurrent(item.dictValue)
        getList(item.dictValue)
    }
    const onDetail = (item) => {
      props.history.push('/news/detail?id='+item.id)
      // console.log(history)
      // window.history.pushState(null, null, '/news/detail?id='+item)
    }
    return (
        <div className="news-page">
            <TopText
                bgUrl="http://image.koayee.cn/ynzFile/2023-11-13/bg_1699860984638.png"
                imgUrl="http://image.koayee.cn/ynzFile/2023-11-13/Group125_1699860963466.png"
                width={697}
            />
            <div className="tabs-list page-class">
                {
                    tabsList.map(item => {
                        return (
                            <div className={['tab-item', item.dictValue === current ? 'check' : ''].join(' ')} key={item.dictValue}
                                 onClick={() => onTab(item)}>{item.dictLabel}</div>
                        )
                    })
                }
            </div>
            <div className="content-box page-class">
                {
                    list.map(item => {
                        let time=item.createTime.substr(0,10).replace(/-/g,'.')
                        return (
                            <div className="item" key={item.id} onClick={()=>onDetail(item)}>
                                <img src={item.pic} alt=""
                                     className="left"/>
                                <div className="right">
                                    <h1 className="title text-overflow">{item.title}</h1>
                                    <p className="desc text-overflow-3">{item.subTitle}</p>
                                    <p className="date">{time}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
});

export default News;

