import React, { memo } from 'react';
import './index.scss'
import {NavLink} from "react-router-dom";

const HomeTop = memo(() => {

  return (
      <div className="home-top">
        <img src="http://image.koayee.cn/ynzFile/2023-11-04/2_1699086149078.png" alt="" className="left-img"/>
          <img src="http://image.koayee.cn/ynzFile/2023-11-10/home_1699609197011.png" alt=""
               className="content-box animate__animated animate__fadeInDown"/>
      </div>
  );
});

export default HomeTop;
