import React, {memo, useState, useEffect} from 'react';
import {useParams} from 'react-router-dom'
import {LeftOutlined} from '@ant-design/icons';
import {newsGetDetail} from '@/services/api.js'
import './index.scss'

const Detail = memo((props) => {

    const [detail,setDetail] =useState({})
    useEffect(()=>{
        window.scrollTo({
            top:0,
            left:0,
            // behavior: "smooth"
        });
        getDetail()
    },[])

    const getDetail = () => {
        const id = window.location.hash.split('=')[1]
        console.log('id=>', id)
        newsGetDetail(id).then(res=>{
            if(res.code===200){
                setDetail(res.data)
            }
        })
    }
    const onBack = () => {
        props.history.go(-1)
    }
    return (
        <div className="news-detail-page">
            <div className="header-title">
                <LeftOutlined className="back-icon" onClick={() => onBack()}/>
                <h1 className="title">{detail.title}</h1>
                <p className="date">{detail.createTime}发布</p>
            </div>
            <div className="content ql-editor">
                <div dangerouslySetInnerHTML={{__html:detail.fcontent}}/>
            </div>
            <div className="footer-bg"/>
        </div>

    );
});

export default Detail;

