import React, {memo} from 'react';
import TopText from '@/components/TopText'
import AboutUs from './AboutUs' //关于蓝波
import GloriousDeeds from './GloriousDeeds' //公司辉煌事迹
import HonorExhibition from './HonorExhibition' //公司荣誉展示
import MembersIntroduction from './MembersIntroduction' //公司成员介绍
import CompanyPartner from './CompanyPartner' //合作伙伴
import DevelopmentMilestones from './DevelopmentMilestones' //公司发展里程碑


import './index.scss'

const About = memo(() => {
    return (
        <div className="about-page">
            <TopText
                bgUrl="http://image.koayee.cn/ynzFile/2023-11-07/1_1699350296400.png"
                imgUrl="http://image.koayee.cn/ynzFile/2023-11-10/1_1699609303976.png"
                width={900}
            />
            <AboutUs/>
            <GloriousDeeds/>
            <HonorExhibition/>
            <MembersIntroduction/>
            <CompanyPartner/>
            <DevelopmentMilestones/>
        </div>

    );
});

export default About;
