import React, {memo} from 'react';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import TopText from '@/components/TopText'
import './index.scss'

const DigitalMall = memo(() => {
    return (
        <div className="digital-mall-page">
            <TopText
                bgUrl="http://image.koayee.cn/ynzFile/2023-11-10/4-bg_1699608386399.png"
                imgUrl="http://image.koayee.cn/ynzFile/2023-11-10/2-4_1699609670375.png"
                width={731}
            />
            <div className="creators-box">
                <h1 className="header-title-box">
                    数字电商<br/>
                    About<br/>
                    DIGITAL MALL
                </h1>
                <div className="right-box">
                    <p className="text-zh">
                        TO B、TO C多元化订单推送机制，AI交互式全闭环订单流程，一键发布马上来人，平台全程监管，实现数字艺术交易、广告收益等更多新颖创收玩法，在实际工作场景中探索应用的更多可能性。
                    </p>
                    <div className="line"/>
                    <p className="text-en">
                        TO B and TO C diversified order push mechanisms, AI interactive closed-loop order process,
                        one-click publishing with immediate response, platform-wide supervision. This achieves more
                        innovative revenue models such as digital art transactions and advertising revenue. It explores
                        more possibilities for application in real-work scenarios.
                    </p>
                </div>
            </div>

            <div className="course-box">
                <h1 className="header-title-box">
                    悬赏展示<br/>
                    Bounty<br/>
                    Display
                </h1>
                <img src="http://image.koayee.cn/ynzFile/2023-11-17/koayee_1700211966304.png" alt="" className="img"/>
            </div>
        </div>
    );
});

export default DigitalMall;
