import request from './request';

//查询字典
export function dataType(data) {
  return request({
    url: '/system/dict/data/type/'+data,
    params: {},
  });
}
//分页获取新闻列表
export function newsList(data) {
  return request({
    url: '/lanbo/news/list',
    params: data,
  });
}
//获取新闻详情
export function newsGetDetail(data) {
  return request({
    url: '/lanbo/news/getDetail/'+data,
    params: data,
  });
}
//分页查询作品列表
export function creatorList(data) {
  return request({
    url: '/lanbo/creator/list',
    params: data,
  });
}
//分页查询岗位列表
export function jobList(data) {
  return request({
    url: '/lanbo/job/list',
    params: data,
  });
}
//公众号分享url
export function getWechatJSShare(data) {
  return request({
    url: '/wechat/getWechatJSShare',
    params: data,
  });
}