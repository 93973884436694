import React, { memo,useState,useEffect } from 'react';
import { LeftOutlined,CloseOutlined } from '@ant-design/icons';

const GloriousDeeds = memo(() => {
  const [show,setShow]=useState(false)
  const [current,setCurrent]=useState(0)

  const onDetail=(index)=>{
    setCurrent(index)
    setShow(true)
  }

  const onClose=()=>{
    setShow(false)
  }

  const onPre=()=>{
    if(current>0){
      setCurrent(current-1)
    }
  }

  const onNext=()=>{
    const len =list.length
    if(current<len-1){
      setCurrent(current+1)
    }
  }

  return (
      <div className="glorious-deeds page-class">
        <h1 className="header-title-box">
          公司辉煌事迹 <br/>
          Glorious <br/>
          deeds
        </h1>
        {
          show?(
              <div className="detail-box">
                <img src="http://image.koayee.cn/ynzFile/2023-11-09/arrow_1699492620083.png" alt="" className="arrow" onClick={()=>onPre()}/>
                <div className="content-box">
                  {/*<LeftOutlined className="back-icon" onClick={()=>onClose()}/>*/}
                  <CloseOutlined className="back-icon" onClick={()=>onClose()}/>
                  <div className="content" style={{transform: `translateX(${-current*1073/16}rem)`}}>
                    {list.map((item,index)=>{
                      return <img src={item.detailUrl} alt="" className="img" key={index}/>
                    })}
                  </div>
                </div>
                <img src="http://image.koayee.cn/ynzFile/2023-11-09/arrow-right_1699492752378.png" alt="" className="arrow" onClick={()=>onNext()}/>
              </div>
          ):(
              <div className="list">
                {list.map((item,index)=>{
                  return (
                      <div className="item" key={item.value} onClick={()=>onDetail(index)}>
                        <img src={item.img} alt=""/>
                        <div className="content-text">
                          <h1 className="title">{item.title}</h1>
                          <p className="text">{item.text}</p>
                        </div>
                      </div>
                  )
                })}
              </div>
          )
        }
      </div>
  );
});

export default GloriousDeeds;

const list=[
  {
    title:'公司AI产品发布会',
    text:'与中信证券，特修斯控股联合，中国国际科协，华创圆桌会议共同举行会议',
    value:1,
    img:'http://image.koayee.cn/ynzFile/2023-11-08/list1_1699409622389.png',
    detailUrl:'http://image.koayee.cn/ynzFile/2023-11-09/1_1699500116080.png'
  },
  {
    title:'阿里巴巴培训体系',
    text:'和阿里巴巴培训体系携手合作，完成培训板块最后一公里',
    value:2,
    img:'http://image.koayee.cn/ynzFile/2023-11-18/list2_1700296619233.png',
    detailUrl:'http://image.koayee.cn/ynzFile/2023-11-18/2_1700296367520.png'
  },
  {
    title:'区块链电商平台',
    text:'联合打造区块链电商鼻祖“GEC环球电商”平台，上线6个月实现6000W+销售额',
    value:3,
    img:'http://image.koayee.cn/ynzFile/2023-11-08/list3_1699409704681.png',
    detailUrl:'http://image.koayee.cn/ynzFile/2023-11-09/3_1699502077327.png'
  },
  {
    title:'区块链技术应用',
    text:'区块链应用技术与行业解决方案沉淀',
    value:4,
    img:'http://image.koayee.cn/ynzFile/2023-11-08/list4_1699409718527.png',
    detailUrl:'http://image.koayee.cn/ynzFile/2023-11-09/4_1699502103957.png'
  },
  {
    title:'品牌IP数字化',
    text:'品牌IP数字化之路',
    value:5,
    img:'http://image.koayee.cn/ynzFile/2023-11-08/list5_1699409734152.png',
    detailUrl:'http://image.koayee.cn/ynzFile/2023-11-09/5_1699502128525.png'
  },
  {
    title:'元宇宙潮流艺术活动',
    text:'打造深圳第一个线上线下结合元宇宙潮流艺术展，推动国潮，虚实结合新形态',
    value:6,
    img:'http://image.koayee.cn/ynzFile/2023-11-08/list6_1699409751053.png',
    detailUrl:'http://image.koayee.cn/ynzFile/2023-11-09/6_1699502147046.png'
  }
]
