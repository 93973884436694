import React, {memo,useState,useEffect} from 'react';

let currentPosition=380
const Franchising = memo(() => {

    useEffect(()=>{
        getNumberLocation()
        window.addEventListener('scroll', getScroll, true)
        return ()=>{
            window.removeEventListener("scroll", getScroll, true);
        }
    },[])

    const getScroll=()=>{
        var _scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        // console.log(_scrollTop)
        if(_scrollTop>currentPosition){
            handleNumber()
        }
    }

    const getNumberLocation=()=> {
        let done = document.getElementById('adv_1');
        let position = done.getBoundingClientRect()
        // console.log(position.top)
        let height = window.innerHeight
        // console.log(done.offsetTop,window.innerHeight)
        currentPosition = position.top - height
        // console.log('currentPosition=>',currentPosition)
        if (currentPosition < 0) {
            handleNumber()
        }
    }
    const handleNumber=()=>{
        window.removeEventListener("scroll", getScroll, true);
        let adv_1 = document.getElementById('adv_1');
        let adv_2 = document.getElementById('adv_2');
        let adv_3 = document.getElementById('adv_3');
        adv_2.classList.add('animate__animated','animate__bounceInLeft')
        adv_1.classList.add('animate__animated' ,'animate__bounceInRight')
        adv_3.classList.add('animate__animated' ,'animate__bounceInRight')
    }
    return (
        <div className="adv-box page-class">
            <img src="http://image.koayee.cn/ynzFile/2023-11-13/Group121_1699842363064.png" alt=""
                 className="img1" id="adv_1"/>
            <img src="http://image.koayee.cn/ynzFile/2023-11-13/Group122_1699842391165.png" alt=""
                 className="img2" id="adv_2"/>
            <img src="http://image.koayee.cn/ynzFile/2023-11-13/Group123_1699842414800.png" alt=""
                 className="img3" id="adv_3"/>
        </div>
    );
});

export default Franchising;
