import React, {memo, useEffect} from 'react';

let numberCurrent=470
const Benefits = memo(() => {

    useEffect(()=>{
        getNumberLocation()
        window.addEventListener('scroll', getScroll, true)
        return ()=>{
            window.removeEventListener("scroll", getScroll, true);
        }
    },[])
    const getScroll=()=>{
        var _scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        // console.log(_scrollTop)
        if(_scrollTop>numberCurrent){
            handleNumber()
        }
    }

    //获取位置
    const getNumberLocation=()=> {
        let done = document.getElementById('homeNumber');
        let position = done.getBoundingClientRect()
        // console.log(position.top)
        let height = window.innerHeight
        // console.log(done.offsetTop,window.innerHeight)
        numberCurrent = position.top - height
        // console.log('numberCurrent=>',numberCurrent)
        if(numberCurrent<0){
            handleNumber()
        }
    }
    const handleNumber=()=>{
        window.removeEventListener("scroll", getScroll, true);
        let item_01 = document.getElementById('item_01');
        let item_02 = document.getElementById('item_02');
        let item_03 = document.getElementById('item_03');
        let item_04 = document.getElementById('item_04');

        item_01.classList.add('animate__animated' ,'animate__bounceInRight')
        setTimeout(()=>{
            item_02.classList.add('animate__animated','animate__bounceInLeft')
        },300)
       setTimeout(()=>{
           item_03.classList.add('animate__animated' ,'animate__bounceInRight')
       },600)
        setTimeout(()=>{
            item_04.classList.add('animate__animated','animate__bounceInLeft')
        },900)
    }
  return (
      <div className="benefits-box page-class">
          <h1 className="header-title-box">
              公司福利<br/>
              Company<br/>
              benefits
          </h1>
          <div className="content-box" id="homeNumber">
              <div className="pro-left"/>
              <div className="pro-right"/>
              {
                  list.map((item,index)=>{
                      return(
                          <div className={['item', `item-0${index+1}`].join(' ')} key={index} id={`item_0${index+1}`}>
                              <p className="num">{'0'+(index+1)}</p>
                              <div className="line"/>
                              {item}
                          </div>
                      )
                  })
              }
          </div>
      </div>
  );
});

export default Benefits;

const list=[
    <p className="text">公司扁平化管理<br/>晋升成长空间大</p>,
    <p className="text">薪资组成：底薪+绩效+项目<br/>奖金+各项补贴，福利齐全</p>,
    <p className="text">办公位置在科韵路地铁站附<br/>近，交通便利、高级写字楼</p>,
    <p className="text">不定期员工团建及旅游，<br/>团队氛围好</p>
]
