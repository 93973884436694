import React, {memo} from 'react';
import TopText from '@/components/TopText'
import './index.scss'

const Investment = memo(() => {
    return (
        <div className="investment-page">
            <TopText
                bgUrl="http://image.koayee.cn/ynzFile/2023-11-14/bg_1699927320591.png"
                imgUrl="http://image.koayee.cn/ynzFile/2023-11-14/Group127_1699927718707.png"
                width={697}
            />
            <div className="header-box">
                <div className="lanbo-the">
                    <img src="http://image.koayee.cn/ynzFile/2023-11-14/logo_1699929361760.png" alt=""
                         className="logo"/>
                    <img src="http://image.koayee.cn/ynzFile/2023-11-14/x_1699929378925.png" alt="" className="x"/>
                    <img src="http://image.koayee.cn/ynzFile/2023-11-14/logo2_1699929398089.png" alt=""
                         className="logo2"/>
                </div>
                < p className="desc-zh">
                    2022年，蓝波（广州）科技有限公司顺利完成第一轮数百万的天使轮投资，<br/>
                    本轮投资由忒修斯集团独家投资，并正式开启元宇宙社交电商平台的布局与打造。
                </p>
                <p className="desc-en">
                    In 2022, Blue Wave (Guangzhou) Technology Co., Ltd. successfully completed its first round of angel
                    investment worth millions of<br/>
                    dollars. This round of investment was exclusively funded by the Theseus Group and formally marked
                    the start of the company's layout <br/>
                    and construction of the Metaverse social e-commerce platform.
                </p>
                <div className="bg"/>
            </div>
            <div className="creators-box page-class">
                <h1 className="header-title-box">
                    关于忒修斯<br/>
                    About<br/>
                    US
                </h1>
                <div className="right-box">
                    <p className="text-zh">
                        忒修斯集团始创于素有“千年商都”的国际化大都市——广州，是一家集电商、科技、投资业务为一体的多元化集团公司。
                        “忒修斯”一词来源于古希腊神话忒修斯之船，寓意突破表面现实及认知，探究本质。忒修斯始终以探索价值本质为商业思考基点，并在商业世界中以创造价值、连接价值、放大价值作为商业布局指引，现旗下拥有窄门投资、黑格伦贸易、窄门科技、中转信息科技等分子机构，逐步形成以电商、科技、投资三大业务板块协同发展的商业格局。
                        电商业务板块:专注于日本高端潮流领域，早于2008
                        年极具前瞻性地选择聚焦玩具模型类目，历经十余年积淀，已成为国内销售日版玩具模型的先驱者和引领者，产品涵盖万代、MEDICOM、千值练、任天堂、索尼等多个国际品牌，为数百万潮流爱好者提供高端预订产品，通过电商业务的深耕积累，建立了丰富的海内外供应链资源，为集团开展多元化业务奠定基础。
                        科技板块：致力于为企业客户及集团提供大数据服务平台建设及企业信息化建设，并做为集团孵化科技项目的关键力量。
                        投资板块：专注于跨境、高新科技等领域的早期投资，透过早期股权投资为创业公司带入中国产业资本、战略资本并提供专业投后服务，帮助被投公司创造更大价值。目前已在科技、区块金融等领域领投多个海内外高潜项目。
                        未来，忒修斯将秉持利他经营哲学，在探寻商业本质的同时，致力于优质项目和优秀人才的有效连接及价值释放，成为一家具有创业创新精神和社会责任感的企业。
                    </p>
                    <div className="line"/>
                    <p className="text-en">
                        Tethys Group was founded in Guangzhou, an international metropolis known as the "millennium
                        commercial capital". It is a diversified group company integrating e-commerce, technology, and
                        investment businesses.

                        The name "Tethys" originates from the ancient Greek myth of the ship of Tethys, symbolizing the
                        breakthrough of surface reality and cognition to explore the essence. Tethys always takes
                        exploring the essence of value as the basis of business thinking and uses value creation, value
                        connection, and value amplification as the guiding principles for business layout in the
                        business world. It now owns subsidiary institutions such as Narrow Gate Investment, Hegge Run
                        Trading, Narrow Gate Technology, and Transshipment Information Technology, gradually forming a
                        business pattern of coordinated development among e-commerce, technology, and investment.
                        E-commerce business sector: It focuses on the high-end trend field in Japan. As early as 2008,
                        it foresightedly chose to focus on the toy and model category. After more than ten years of
                        accumulation, it has become a pioneer and leader in selling Japanese toy and model products
                        domestically. Its products cover various international brands such as Bandai, MEDICOM, Thousand
                        Value Practice, Nintendo, and Sony, providing high-end pre-order products to millions of trend
                        enthusiasts. Through deep cultivation and accumulation in e-commerce business, it has
                        established a rich supply chain resources both at home and abroad, laying a foundation for the
                        group's diversified businesses.
                        Technology sector: It is committed to providing big data service platform construction and
                        enterprise informatization services for enterprise clients and the group, and serves as a key
                        force in incubating technological projects for the group.
                        Investment sector: It focuses on early-stage investments in cross-border, high-tech, and other
                        fields. Through early equity investments, it brings in Chinese industrial capital and strategic
                        capital for startup companies and provides professional post-investment services to help
                        investee companies create greater value. Currently, it has led investments in multiple
                        high-potential projects at home and abroad in the fields of technology and blockchain finance.
                        In the future, Tethys will adhere to the philosophy of altruistic management and, while
                        exploring the essence of business, dedicate itself to the effective connection and value release
                        of quality projects and outstanding talents, becoming a company with an entrepreneurial and
                        innovative spirit and a sense of social responsibility.
                    </p>
                </div>
            </div>

            <div className="logo-box page-class">
                <h1 className="header-title-box">
                    意向投资机构<br/>
                    Intended<br/>
                    investment institution
                </h1>
                <div className="logo-list">
                    {list.map((item, index) => {
                        return (
                            <img src={item} alt="" className="item" key={index}/>
                        )
                    })}
                </div>
            </div>
        </div>
    );
});

export default Investment;

const list = [
    "http://image.koayee.cn/ynzFile/2023-11-14/1_ali_1699930250403.png",
    "http://image.koayee.cn/ynzFile/2023-11-14/2_zijie_1699930255014.png",
    "http://image.koayee.cn/ynzFile/2023-11-14/3_gylm_1699930259318.png",
    "http://image.koayee.cn/ynzFile/2023-11-14/4_idg_1699930265097.png",
    "http://image.koayee.cn/ynzFile/2023-11-14/5_jiashi_1699930270131.png",
    "http://image.koayee.cn/ynzFile/2023-11-14/6_mayi_1699930273948.png",
    "http://image.koayee.cn/ynzFile/2023-11-14/7_mayilian_1699930277772.png",
    "http://image.koayee.cn/ynzFile/2023-11-14/8_meihua_1699930281713.png",
    "http://image.koayee.cn/ynzFile/2023-11-14/9_pinan_1699930284797.png",
    "http://image.koayee.cn/ynzFile/2023-11-14/10_puhua_1699930288034.png",
    "http://image.koayee.cn/ynzFile/2023-11-14/11_jiyuan_1699930290962.png",
    "http://image.koayee.cn/ynzFile/2023-11-14/12_chuangxin_1699930293910.png",
    "http://image.koayee.cn/ynzFile/2023-11-14/13_hongsan_1699930297110.png",
    "http://image.koayee.cn/ynzFile/2023-11-14/14_jinsha_1699930300814.png",
    "http://image.koayee.cn/ynzFile/2023-11-14/15_jinwei_1699930305195.png",
    // "http://image.koayee.cn/ynzFile/2023-11-14/16_theseus_1699930308692.png"
]
