import React, {memo} from 'react';
import './index.scss'
import {NavLink} from "react-router-dom";

const HomeTop = memo((props) => {
    const onGo=()=>{
        props.history.push('/about')
    }
    return (
        <div className="home-future">
            <img src="http://image.koayee.cn/ynzFile/2023-11-06/future-1_1699258133763.png" alt=""
                 className="left-img"/>
            <img src="http://image.koayee.cn/ynzFile/2023-11-06/future-2_1699259489255.png" alt=""
                 className="right-img"/>
            <div className="content-box">
                <div className="header-title">
                    <span className="text">Lanqing</span>
                    <span className="text bold">Future</span>
                </div>
                <h1 className="title">“蓝擎·未来”</h1>
                <button className="button" onClick={()=>onGo()}>了解更多</button>
            </div>

        </div>
    );
});

export default HomeTop;
