import { combineReducers } from 'redux-immutable';
// import { reducer as recommendReducer } from '../pages/discover/c-pages/recommend/store';
import { Map } from 'immutable';

const defaultState = Map({
    topBanners: [],
    // hotRecommends: [],
    // newAlbums: [],
    //
    // upRanking: {},
    // newRanking: {},
    // originRanking: {},
});
function reducer(state = defaultState, action) {
    // switch (action.type) {
    //     case actionTypes.CHANGE_TOP_BANNERS:
    //         return state.set('topBanners', action.topBanners);
    //
    //     default:
    //         return state;
    // }
return state
}
const cReducer = combineReducers({
    reducer: reducer,
});

export default cReducer;
