import React, { memo, useState, useEffect } from 'react';

const DevelopmentMilestones = memo(() => {

  const [current, setCurrent] = useState(0)

  const onPre = () => {
    const len = list.length
    if (current > 0) {
      setCurrent(current - 1)
    }
  }
  const onNext = () => {
    const len = list.length
    if (current < len - 1) {
      setCurrent(current + 1)
    }
  }
  return (
    <div className="development-milestones page-class">
      <h1 className="header-title-box">
        公司发展里程碑 <br />
        Milestones of <br />
        company development
      </h1>
      <div className="content-box">
        <img src="http://image.koayee.cn/ynzFile/2023-11-09/arrow_1699492620083.png" alt="" className="arrow" onClick={() => onPre()} />
        <div className="list-box">
          {/*<div className="list" style={{transform:`translateX(${-current*250/16+500/16}rem)`}}>*/}
          <div className="list" style={{ left: -current * 250 / 16 + 500 / 16 + 'rem' }}>
            {
              list.map((item, index) => {
                return (
                  <div className={[index % 2 ? 'item-bottom' : 'item-top', index === current ? 'current' : ''].join(' ')} style={{ left: 250 * index / 16 + 'rem' }} key={index}>
                    <p className="desc">{item.desc}</p>
                    <img src="http://image.koayee.cn/ynzFile/2023-11-09/line_1699492471732.png" alt=""
                      className="img" />
                    <p className="month">{item.month}</p>
                  </div>
                )
              })
            }
            {
              list.map((item, index) => {
                if (index < list.length - 1) {
                  return (
                    <div className="line" style={{ left: 250 * index / 16 + 11.5 + 'rem' }} key={index} />
                  )
                }
              })
            }

            {/*<div className="line"/>*/}
          </div>
        </div>
        <img src="http://image.koayee.cn/ynzFile/2023-11-09/arrow-right_1699492752378.png" alt="" className="arrow" onClick={() => onNext()} />
      </div>
    </div>
  );
});

export default DevelopmentMilestones;

const list = [
  {
    month: '2023.12',
    desc: '中华职教社 (副国级)中华职教集团副总裁卢总的考察与指导，并与蓝波科技签订战略合作双方将基于人工智能方向的高校专业共建，人才孵化，个团赛事和职业认证等全面合作'
  },
  {
    month: '2023.11',
    desc: '签约并入驻英特尔创新中心（中国），合力构建英特尔国内生态体系与联动上下游拥抱人工智能时代的爆发。'
  }, {
    month: '2023.8',
    desc: '蓝波科技与阿里巴巴认证战略合作，成为阿里巴巴认证官方合作机构。'
  }, {
    month: '2023.6',
    desc: '全球第一个元宇宙社交电商平台— “KOAYEE 元 宇宙” 正式上线。'
  }, {
    month: '2023.4',
    desc: '推出Midjourney中文版项目KOJOUR- NEY，将全球顶级AI绘画能力引入中国市场，引爆中国AI圈。'
  }, {
    month: '2023.3',
    desc: '开发并上线ChatGPT中文版项目KO GPT，一周实现过万访客，并与多位抖音大网红联合推出多场相关活动。'
  }, {
    month: '2022.11',
    desc: '公司获得富有哲科思维的投资新秀“窄门投资” 数百万元天使轮投资，开始启动元宇宙社交电商平台的布局与打造。'
  }, {
    month: '2022.8',
    desc: '公司正式宣布大力进军生成式 AI（AIGC）和通用人工智能（AGI）赛道。'
  }, {
    month: '2022.5',
    desc: '核心创始人发起并运营数字艺术平台 “Arthike 元宇宙”，并为上市公司正佳集 团发行艺术 IP，为马士基创新中心创建数字IP形象并发行企业级数字纪念品'
  }, {
    month: '2022.3',
    desc: '入驻成为全球航运龙头马士基航运技术供应商，  并联合开发全国第一个物流行业双碳标准项目—马士基碳链系统。'
  }, {
    month: '2021.12',
    desc: '核心创始人获港股上市公司大中华国际集团投资并创立深圳第一家商业实体虚实结合数字艺术平台“Mytrol 数字文创空间”，打造国内第一个数字艺术潮流IP展并实百万销售'
  }, {
    month: '2021.9',
    desc: '参与区块链落地解决方案超过 30+ 行业场景,区块链赋能实体场景业务咨询突破 300+'
  }, {
    month: '2021.7',
    desc: '受邀参加世界信息与社会信息日（第三届信息通信产业高峰论坛）发表主题演讲并获得《区块链产业应用技术创新奖》。'
  }, {
    month: '2021.5',
    desc: '公司自研NBLOCK Engine区块链中间件， 并基于BaaS自研工贸一体化区块链溯源系统——正源链，集合区块链底层溯源能力，供应链管理，智慧门店管理，软件服务商管理，VIP 客户管理为一体的新 零售溯源营销系统。'
  }, {
    month: '2020.10',
    desc: '签约成为蚂蚁区块链官方节点伙伴， 并成为蚂蚁开 放联盟 链链上节点。'
  }, {
    month: '2020.9',
    desc: '公司由区块链电商鼻祖GEC  环球 电商（Gojoy）创始成员与国内互联网大 厂高管共同成立',
  },
]
