import React, {memo,useState,useEffect} from 'react';


const Conditions = memo(() => {

    return (
        <div className="conditions-box page-class">
            <div className="content-box">
                {
                    list_left.map((item, index) => {
                        return (
                            <div className="item item-left" key={index}>
                                <h1 className="title">{item.title}</h1>
                                <img src="http://image.koayee.cn/ynzFile/2023-11-13/con-3_1699857067742.png" alt=""
                                     className="line"/>
                                {
                                    item.textList.map((j, jIndex) => {
                                        return (
                                            <p className="text" key={jIndex}>{j}</p>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
            <div className="content-center">
                <p className="icon"/>
                <p className="text">加盟条件</p>
            </div>

            <div className="content-box">
                {
                    list_right.map((item, index) => {
                        return (
                            <div className="item" key={index}>
                                <h1 className="title">{item.title}</h1>
                                <img src="http://image.koayee.cn/ynzFile/2023-11-13/con-2_1699857439260.png" alt=""
                                     className="line"/>
                                {
                                    item.textList.map((j, jIndex) => {
                                        return (
                                            <p className="text"
                                               style={{whiteSpace: index === 0 ? 'nowrap' : 'unset'}}
                                               key={jIndex}>{j}</p>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
});

export default Conditions;

const list_left = [
    {
        title: '申请人要求',
        textList: [
            '建议年龄在20-45周岁，身体健康，具备自主经营能力；',
            '认同公司经营理念、企业文化及品牌价值。对品牌充满信心，有与公司共同发展的强烈愿望；',
            '具备一定的风险承担意识；',
            '必须遵守国家法律法规。'
        ],
    },
    {
        title: '业务开发',
        textList: [
            '有相关业务资源及基础计算机运用能力；',
            '具备长期经营和发展的精力。'
        ],
    }
]

const list_right = [
    {
        title: '保证金',
        textList: ['10000元，合作期内无违约行为，全额退回']
    },
    {
        title: '骨干成员',
        textList: ['建议配备1-2名骨干成员']
    },
    {
        title: '学习成长',
        textList: [
            '具备客户第一的服务意识，本人及骨干成员在合作期间须接受公司的系统培训，运营顾问辅导；',
            '积极配合公司的市场运作，遵纪守法，规范经营。'
        ]
    },
]
