import React, {memo} from 'react';
import {Provider} from 'react-redux';
import {renderRoutes} from 'react-router-config';
import ReactCanvasNest from 'react-canvas-nest';

import routes from './router';
import store from './store';

import AppFooter from '@/components/app-footer';
import AppHeader from '@/components/app-header';

import '@/assets/css/reset.css';
import {HashRouter} from 'react-router-dom';
import 'animate.css';

import 'quill/dist/quill.core.css' // 引入样式
import 'quill/dist/quill.snow.css' // snow theme
import 'quill/dist/quill.bubble.css' // bubble theme

const App = memo(() => {
    return (
        <Provider store={store}>
            <HashRouter>
                <ReactCanvasNest config={{lineColor: '26, 113, 255'}}/>
                <AppHeader></AppHeader>
                {renderRoutes(routes)}
                <AppFooter></AppFooter>
            </HashRouter>
        </Provider>
    );
});

export default App;
