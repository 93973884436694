import React, { memo } from 'react';

const HonorExhibition = memo(() => {
  return (
      <div className="honor-exhibition page-class">
        <h1 className="header-title-box">
          公司荣誉展示 <br/>
          Company <br/>
          Honor Exhibition
        </h1>
        <img src="http://image.koayee.cn/ynzFile/2023-11-08/3_1699412231249.png" alt="" className="img"/>
      </div>
  );
});

export default HonorExhibition;
