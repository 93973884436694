import React, {memo, useState, useEffect} from 'react';

let index = 0
const CompanyPartner = memo(() => {
    const [style, setStyle] = useState()
    const [list, setList] = useState([])
    useEffect(() => {
        let endList = imgList.slice(0, 15)
        setList([...imgList, ...endList])
    }, [])

    useEffect(() => {
        let timer = setInterval(() => {
            // console.log(line,index)
            index++
            setStyle({
                transition: index ? 'transform 1s' : 'none',
                transform: `translateY(-${index * 143 / 16}rem)`
            })
        }, 3000)
        return ()=>{
            index=0
          clearInterval(timer)
        }
    }, [])

    const onEnd = () => {
        let line = list.length / 5
        // console.error(line,index)
        if (index >= line - 3) {
            index = 0
            setStyle({
                transition: 'none',
                transform: `translateY(-${index * 143 / 16}rem)`
            })
        }
    }
    return (
        <div className="company-partner page-class">
            <h1 className="header-title-box">
                合作伙伴 <br/>
                Company <br/>
                Partner
            </h1>
            <div className="list-box">
                <div className="list" style={style} onTransitionEnd={() => onEnd()}>
                    {list.map((item, index) => {
                        return <img src={item} alt="" className="item" key={index}/>
                    })}
                </div>
            </div>
        </div>
    );
});

export default CompanyPartner;

const imgList = [
    'http://image.koayee.cn/ynzFile/2023-11-14/1_1699942941386.png',
    'http://image.koayee.cn/ynzFile/2023-11-14/2_1699942944766.png',
    'http://image.koayee.cn/ynzFile/2023-11-14/3_1699942948079.png',
    'http://image.koayee.cn/ynzFile/2023-11-14/4_1699942952830.png',
    'http://image.koayee.cn/ynzFile/2023-11-14/5_1699942956400.png',
    'http://image.koayee.cn/ynzFile/2023-11-14/6_1699942959467.png',
    'http://image.koayee.cn/ynzFile/2023-11-14/7_1699942962555.png',
    'http://image.koayee.cn/ynzFile/2023-11-14/8_1699942965736.png',
    'http://image.koayee.cn/ynzFile/2023-11-14/9_1699942968950.png',
    'http://image.koayee.cn/ynzFile/2023-11-14/10_1699942972661.png',
    'http://image.koayee.cn/ynzFile/2023-11-14/11_1699942976136.png',
    'http://image.koayee.cn/ynzFile/2023-11-14/12_1699942979104.png',
    'http://image.koayee.cn/ynzFile/2023-11-14/13_1699942982710.png',
    'http://image.koayee.cn/ynzFile/2023-11-14/14_1699942986420.png',
    'http://image.koayee.cn/ynzFile/2023-11-14/15_1699942990492.png',
    'http://image.koayee.cn/ynzFile/2023-11-14/16_1699942994471.png',
    'http://image.koayee.cn/ynzFile/2023-11-14/17_1699942998307.png',
    'http://image.koayee.cn/ynzFile/2023-11-14/18_1699943001856.png',
    'http://image.koayee.cn/ynzFile/2023-11-14/19_1699943005398.png',
    'http://image.koayee.cn/ynzFile/2023-11-14/20_1699943010331.png',
]
