import React, { memo,useState,useEffect } from 'react';
import './index.scss'
import {NavLink} from "react-router-dom";

let number0=0 //10
let number1=0 //88
let number2=0 //60
let number3=0 //100

let numberCurrent=380
const HomeNumber = memo(() => {
    let [num,setNum]=useState(0)

    useEffect(()=>{
        getNumberLocation()
    },[])

    useEffect(()=>{
        window.addEventListener('scroll', getScroll, true)
        return ()=>{
            number0=0
            number1=0
            number2=0
            number3=0
            window.removeEventListener("scroll", getScroll, true);
        }
    },[])
    const getScroll=()=>{
        var _scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        // console.log(_scrollTop)
        if(_scrollTop>numberCurrent){
            handleNumber()
        }
    }

    const handleNumber=()=>{
        window.removeEventListener("scroll", getScroll, true);
        let listBox_0 = document.getElementById('listBox_0');
        let listBox_1 = document.getElementById('listBox_1');
        listBox_0.classList.add('animate__animated','animate__bounceInLeft')
        listBox_1.classList.add('animate__animated' ,'animate__bounceInRight')

       let  timer0 = setInterval(() => {
            // console.error('scrollTop=>',scrollTop,timer0)
            if (number3 < 100) {
                setNum(number3)
                if(number0<10){
                    number0++
                }
                if(number1<=88-4){
                    number1+=4
                }
                if(number2<=60-4){
                    number2+=4
                }
                if(number3<=100-4){
                    number3+=4
                }
            } else {
                clearInterval(timer0)
            }
        }, 100)
    }

    //获取位置
    const getNumberLocation=()=> {
        let done = document.getElementById('homeNumber');
        let position = done.getBoundingClientRect()
        // console.log(position.top)
        let height = window.innerHeight
        // console.log(done.offsetTop,window.innerHeight)
        numberCurrent = position.top - height
        // console.log('numberCurrent=>',numberCurrent)
        if(numberCurrent<0){
            handleNumber()
        }
    }
  return (
      <div className="home-number" id="homeNumber">
        <div className="list-box" id="listBox_0">
           <div className="item-0">
               <p className="number">{number0}</p>
               <h1 className="title">公司团队</h1>
               <p className="text">在AI技术的加持下，<br/>
                   技术团队只占30人的科技公司比例</p>
           </div>
           <div className="item-1">
               <p className="number">{number1}</p>
               <h1 className="title">公司业务</h1>
               <p className="text">公司主要业务来源AI<br/>
                   相关合作和业务，占公司业务收益比例</p>
           </div>
        </div>
        <div className="list-box1" id="listBox_1">
           <div className="item-1">
               <p className="number">{number2}</p>
               <h1 className="title">公司设备</h1>
               <p className="text">公司投入的AI设备、<br/>
                   教程、培训和平台研发，占公司支出</p>
           </div>
           <div className="item-0">
               <p className="number">{number3}</p>
               <h1 className="title">投入时间</h1>
               <p className="text">公司成立以来，对于<br/>
                   AI项目投入的时间和精力是绝对的</p>
           </div>
        </div>
      </div>
  );
});

export default HomeNumber;
