import React, {memo} from 'react';

const MembersIntroduction = memo(() => {
    return (
        <div className="members-introduction page-class">
            <h1 className="header-title-box">
                公司成员介绍 <br/>
                Introduction <br/>
                to Company Members
            </h1>
            <div className="ceo-box">
                <div className="left">
                    <img src="http://image.koayee.cn/ynzFile/2023-11-08/member1_1699413218110.png" alt=""
                         className="ava"/>
                    <p className="name">Rickey Chen</p>
                    <p className="position">职位：CEO</p>
                </div>
                <p className="right">
                    AIGC解决方案专家/区块链BaaS专家/元宇宙场景架构师擅长区块链BAAS、30+以上行业区块链解决方案，并深耕于元宇宙场景化应用打造。曾联合创立区块链电商鼻祖平台—GEC环球电商，并架构了国内第一个去中心化DAO社区，半年实现6000多万销售。创立多家数字艺术品交易平台，并开创国内第一个数字艺术品与实体权益赋能经济体，实现100W+用户量的暴增及千万销售。现率先在国内架构并实施针对不同行业的AIGC解决方案，含基于通用语言模型的行业知识库，电商场景化AIGC解决方案，AIGC数字电商等，并设计了“AI教学—AI创作—AI媒体—数字电商”为一体的AIGC全闭环生态，同步融合公司区块链，品牌数字艺术，社交电商，元宇宙场景架构等能力，致力于打造AIGC数字电商的第一股，目前担任CEO一职，全面负责整体项目场景化搭建与落地实施。
                </p>
            </div>
          <div className="list-box">
            {list.map(item=>{
              return (
                  <div className="item" key={item.value}>
                    <img src={item.img} alt=""/>
                    <div className="content-text">
                      <h1 className="name">{item.name}</h1>
                      <p className="position">职位：{item.position}</p>
                      <p className="text">{item.text}</p>
                    </div>
                  </div>
              )
            })}
          </div>
        </div>
    );
});

export default MembersIntroduction;

const list=[
  {
    name:'Happy Li',
    position:'CMO',
    value:1,
    text:'十多年的广告行业经验，参与众多品牌的新品发布会、年会、会展展览等大型活动，包括线上的营销策划、视频制作拍摄、直播带货等。合作的品牌有英特尔、微软、PWC、博世、奔驰、天猫、海天、奥普等等',
    img:'http://image.koayee.cn/ynzFile/2023-11-08/member2_1699413965149.png'
  },
  {
    name:'Paul Lin',
    position:'CTO',
    value:2,
    text:'资深AI算法工程师，尤其精通深度学习、机器学习和数据掘等领域。在人工智能领域有着超过10年的经验，并曾在多个科技公司任职，担任重要的AI算法工程师职务目前主要负责模型算法在AIGC领域的分析、落地和优化。',
    img:'http://image.koayee.cn/ynzFile/2023-11-08/member3_1699413978240.png'
  },
  {
    name:'Jian Chen',
    position:'COO',
    value:3,
    text:'资深互联网运营专家，16 年行业操盘经验。曾服务于“江小白”品牌集团，全道营销操盘10亿  +。对传统电商、新零 售、O2O、F2C、社群运营等商业模式,都有非常专业的行业实操经验对确定市场整体营销战略',
    img:'http://image.koayee.cn/ynzFile/2023-11-08/member4_1699414008417.png'
  },
  {
    name:'Lam Zeng',
    position:'CGO',
    value:4,
    text:'9年新媒体运营、虚拟产品流量投放资深经验,广州探么剧本杀品牌联合创始人，1年内完成品牌搭建以及广州、咸宁、福州3个地区加盟合作，参与体验突破10万+人次。',
    img:'http://image.koayee.cn/ynzFile/2023-11-08/member5_1699414029862.png'
  },
]
