import React, { memo } from 'react';
import { renderRoutes } from 'react-router-config';

const Core = memo((props) => {
  const { route } = props;
  return (
      <div className="core-page">
        {renderRoutes(route.routes)}
      </div>
  );
});

export default Core;
