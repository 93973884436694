import React, { memo,useState,useEffect } from 'react';
import HomeTop from './HomeTop'
import HomeNumber from './HomeNumber'
import HomeSwipe from './HomeSwipe'
import HomeFuture from './HomeFuture' //蓝擎未来
import wxShare from '@/utils/wxShare.js'
import './index.scss'

const Home = memo((props) => {
    useEffect(()=>{
        window.scrollTo({
            top:0,
            left:0,
            // behavior: "smooth"
        });
        // setTimeout(()=>{
        //   wxShare()
        // },1000)
    },[])
  return (
      <div className="home-page">
        <HomeTop/>
        <HomeNumber/>
        <HomeSwipe history={props.history}/>
        <HomeFuture history={props.history}/>
      </div>
  );
});

export default Home;
