import React, { memo,useEffect } from 'react';
import './index.scss'

const TopText = memo(({bgUrl,imgUrl,width}) => {
    useEffect(()=>{
        window.scrollTo({
            top:0,
            left:0,
            // behavior: "smooth"
        });
    },[])

//animate__animated animate__fadeInDown
  return (
      <div className="top-text-page" style={{backgroundImage: `url(${bgUrl})`}}>
          <div className="content-box ">
              <img className="animate__animated animate__fadeInDown" src={imgUrl} alt="" style={{width:width/16+'rem'}}/>
          </div>
      </div>
  );
});

export default TopText;
