import React from 'react';
import { Redirect } from 'react-router';

import home from '@/pages/home';
import about from '@/pages/about';

import Core from '@/pages/core';
import Creators from '@/pages/core/creators';
import NewMedia from '@/pages/core/new-media';
import SkillTraining from '@/pages/core/skill-training';
import DigitalMall from '@/pages/core/digital-mall';

import join from '@/pages/join';
import news from '@/pages/news';
import newsDetail from '@/pages/news/detail';
import investment from '@/pages/investment';
import recruitment from '@/pages/recruitment';

const routes = [
  {
    path: '/',
    exact: true,
    render: () => <Redirect to="/home" />,
  },
  {
    path: '/home',
    exact: true,
    component:home
  },
  {
    path: '/about',
    exact: true,
    component: about,
  },
  {
    path: '/core',
    component: Core,
    routes:[
      {
        path: '/core',
        exact: true,
        render: () => <Redirect to="/core/creators" />,
      },
      {
        path: '/core/creators',
        component: Creators,
      },
      {
        path: '/core/new-media',
        component: NewMedia,
      },
      {
        path: '/core/skill-training',
        component: SkillTraining,
      },
      {
        path: '/core/digital-mall',
        component: DigitalMall,
      },
    ]
  },
  {
    path: '/join',
    exact: true,
    component: join,
  },
  {
    path: '/news',
    exact: true,
    component: news,
  },
  {
    path: '/news/detail',
    exact: true,
    component: newsDetail,
  },
  {
    path: '/investment',
    exact: true,
    component: investment,
  },
  {
    path: '/recruitment',
    exact: true,
    component: recruitment,
  },
];

export default routes;
