import React, {memo, useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';

import './index.scss'

const AppHeader = memo(() => {
    const [showMask,setShowMask]=useState(false)
    useEffect(()=>{
        window.addEventListener('scroll', getScroll, true)
    },[])
    const getScroll=()=>{
        var _scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        // console.log(_scrollTop)
        setShowMask(_scrollTop>150)
    }
    const menuList=[
        {
            title: '关于蓝波',
            link: '/about',
        },
        {
            title: '核心业务',
            link: '/core',
        },
        {
            title: '加盟资讯',
            link: '/join',
        },
        {
            title: '新闻中心',
            // link: '/friend',
            link: '/news',
        },
        {
            title: '投资赋能',
            link: '/investment',
        },
        {
            title: '招聘信息',
            link: '/recruitment',
        },
    ]
    const coreSubList= [
        {
            title: '创作者联盟',
            link: '/core/creators',
        },
        {
            title: 'AI新媒体',
            link: '/core/new-media',
        },
        {
            title: 'AI技能培训',
            link: '/core/skill-training',
        },
        {
            title: '数字商城',
            link: '/core/digital-mall',
        },
    ]
    return (
      <div className="app-header">
          {showMask&& <div className="mask-bg"></div>}
          <div className="header-wrapper">
              <NavLink to="/" exact>
                  <img src={require('@/assets/img/logo.png')} alt="" className="logo"/>
              </NavLink>
              <div className="header-right">
                  {menuList.map((item,index)=>{
                      return (
                          <div className={['item',index===1?'item-core':''].join(' ')} key={index}>
                              <NavLink to={item.link} activeClassName="item-check">
                                  {item.title}
                              </NavLink>
                              {
                                  index===1&&(
                                      <div className="core-menu">
                                          <div className="menu-list">
                                              <div className="arrow"/>
                                              {coreSubList.map((item,index)=>{
                                                  return (
                                                      <div className="menu" key={index}>
                                                          <NavLink to={item.link} exact activeClassName="current">
                                                              {item.title}
                                                          </NavLink>
                                                      </div>
                                                  )
                                              })}
                                          </div>
                                      </div>
                                  )
                              }
                          </div>
                      )
                  })}

              </div>
          </div>
      </div>
  );
});

export default AppHeader;
