import React, {memo,useState,useEffect} from 'react';
import TopText from '@/components/TopText'
import Franchising from './Franchising'
import Rights from './Rights'
import Conditions from './Conditions'
import './index.scss'

const Join = memo(() => {

    return (
        <div className="join-page">
            <TopText
                bgUrl="http://image.koayee.cn/ynzFile/2023-11-13/Group48_1699841617231.png"
                imgUrl="http://image.koayee.cn/ynzFile/2023-11-13/Group120_1699841522920.png"
                width={697}
            />
            <h1 className="header-title-box page-class">
                加盟优势<br/>
                Advantages<br/>
                of Franchising
            </h1>
            <Franchising/>

            <h1 className="header-title-box page-class">
                加盟权益<br/>
                Franchise<br/>
                rights
            </h1>
            <Rights/>

            <h1 className="header-title-box page-class">
                加盟条件<br/>
                Franchise<br/>
                conditions
            </h1>
            <Conditions/>

            <div className="process-box page-class">
                <h1 className="header-title-box">
                    加盟流程<br/>
                    Franchise<br/>
                    Process
                </h1>
                <img src="http://image.koayee.cn/ynzFile/2023-11-13/Group124_1699859101921.png" alt=""
                     className="content-img"/>
                <div className="bg-top-left"/>
                <div className="bg-top-right"/>
                <div className="bg-bottom-left"/>
                <div className="bg-bottom-right"/>
            </div>
        </div>
    );
});

export default Join;

