import React, {memo, useState,useEffect} from 'react';
import {jobList} from '@/services/api.js'

const Position = memo(() => {

    const [list,setList]=useState([])
    useEffect(()=>{
        jobList().then(res=>{
            if(res.code===200){
                setList(res.rows)
            }
        })
    },[])

  return (
      <div className="position-box page-class">
          <h1 className="header-title-box">
              招聘岗位<br/>
              position<br/>
              for recruitment
          </h1>
          <div className="list">
              {
                  list.map((item,index)=>{
                      return (
                          <div className="item" key={index}>
                              <h1 className="header-box text-overflow">{item.jobName}</h1>
                              <div className="content-box" dangerouslySetInnerHTML={{__html:item.jobContent}}></div>
                              <a className="more-box" href={item.remark} target="_blank">了解更多> </a>
                          </div>
                      )
                  })
              }
          </div>
      </div>
  );
});

export default Position;

const list= [
    {
        title:'产品经理(APP&小程序)',
        desc:<p>
            项目信息：相关人工智能绘画方面的工作，喜欢此行业或的可看公司简介 <br/>
            岗位职责：<br/>
            1.负责公司App和小程序的产品规划、设计、开发、运营以及优化迭代，通过收集用户需求、研究市场趋势，带领团队打造用户体验好、具备市场竞争力的产品。
        </p>,
        url:'https://www.baidu.com/'
    },
    {
        title:'运营助理',
        desc:<p>
            岗位职责：<br/>
            1.协助运营经理对推广渠道的机制、权益进行日常维护、更换等基础运营工作；<br/>
            2.负责数多个社群的sop流程工作完整一天的事务，如每日发文、公众号软件编辑、内容创意，内容推广策略等；<br/>
            3.协助社群运营对用户，及kol，koc的更好沟通进行进一步工作；<br/>
            4.执行客群分类策略和内容精准推送协助各相关部门推动客群转化和成长；<br/>
            5.积极跟进客诉及后续处理，维护公司形象；<br/>
            6.小程序日常运营维护，员工行为数据分析和挖掘，收集用户反馈，制定和提供产品改善策略。
        </p>,
        url:'https://www.baidu.com/'
    },
    {
        title:'高级JAVA开发工程师',
        desc:<p>
            岗位职责：<br/>
            1.负责公司平台服务端架构、系统功能等设计及开发，及必要的测试和后期维保工作，与客户端开发形成良好协作；<br/>
            2.保证代码质量、系统性能优化，主导技术难题攻关，保障系统运行安全、稳定；<br/>
            3.参与小程序系统的需求调研，协助需求分析师撰写相关文档；<br/>
            4.有社交电商行业相关开发经验；<br/>
            5.有推广返佣体系架构经验，并承接过高并发大项目；<br/>
            6.全栈工程师优先考虑。
        </p>,
        url:'https://www.baidu.com/'
    },
    {
        title:'Web前端工程师',
        desc:<p>
            岗位职责：<br/>
            1.负责小程序开发、PC端与H5端开发、精通跨平台开发；<br/>
            2、通过技术手段，提升用户体验并满足高性能要求；<br/>
            3.参与中后台搭建相关的核心技术、平台的研发；<br/>
            4.负责公司各产品易用性改进、界面优化和性能优化；<br/>
            5.独立能力强，有前端或者ui修图知识的 可以优先考虑；<br/>
            注意：主要项目是以自研项目为主，研发人员不多，要能接受初创1年多的科技公司即可进入了解。
        </p>,
        url:'https://www.baidu.com/'
    },
]
